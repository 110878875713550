<div class="message" [ngStyle]="{marginTop: appConfig.type === 'user' ? '80px': 0}">
  <div class="container">
    <div class="row">
      <div class="col-md-12 mb-3">
        <div class="breadcrumb fs-16 d-md-flex d-none">
          <p class="active cursor-pointer mb-0" [routerLink]="['/', appConfig.type, 'orders']">{{'negotiation' | translate | uppercase}}</p>
          <span>/</span>
          <p class="mb-0">{{'rfq_negotiation' | translate | uppercase}}</p>
        </div>
      </div>
      <div class="col-md-12 mb-3 d-flex justify-content-center">
        <div class="title">
          <h2 class="fs-32 fw-bold">
            {{'negotiation' | translate}}
          </h2>
        </div>
      </div>
      <div class="col-lg-6 mb-4">
        <div class="order-list">
          <div class="shipping-address">
            <div class="w-50">
              <h6 class="fs-16 fw-bold ">
                {{'shipping_address' | translate}}
              </h6>
              <p *ngIf="negotiationDetails?.shippingId">
                {{negotiationDetails?.shippingId?.streetName}}
                , {{negotiationDetails?.shippingId?.adressLine2}},
                {{negotiationDetails?.shippingId?.city}}, {{negotiationDetails?.shippingId?.state}}
                , {{negotiationDetails?.shippingId?.country}}, {{negotiationDetails?.shippingId?.postalCode}}
              </p>

              <p *ngIf="negotiationDetails?.shippingAddress">
                {{negotiationDetails?.shippingAddress}}
              </p>
            </div>
          </div>
          <div class="product-section product-data d-flex mb-2" *ngIf="productDetail">
            <div class="position-relative w-auto d-inline-block me-3">
              <div class="product-image">
                <div class="img-wrapper">
                  <img
                    class="w-100 h-100"
                    [lazyLoad]="getImage(productDetail, 'medium')"
                    defaultImage="assets/images/loaders/skeleton_loader.gif"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div class="product-name w-100">
              <div class="price-title justify-content-sm-start justify-content-between">
                <div class="product-name-price d-flex mb-0 justify-content-between">
                  <div class="d-sm-flex price-details justify-content-between align-items-center w-100 mt-1">
                    <ng-container *ngIf="productDetail.type === 'digital'; else physicalProduct">
                      <h6 class="fs-16 font-weight-medium mb-0 product-name" [title]="productDetail.title">
                        {{ productDetail.title }}
                      </h6>
                      <kwot-product-price
                        [price]="productDetail" tag="span" class="brand-black product-price-size fw-bold" field="price"
                        [platformFee]="false">
                      </kwot-product-price>
                    </ng-container>
                    <ng-template #physicalProduct>
                      <h6 class="fs-16 font-weight-medium mb-0 product-name " [title]="productDetail.productName">
                        {{ productDetail.productName }}
                      </h6>
                      <kwot-product-price
                        [price]="productDetail"
                        [type]="'cart'"
                        tag="span" class="brand-black product-price-size fw-bold"
                        [platformFee]="true">
                      </kwot-product-price>
                    </ng-template>
                  </div>
                </div>
                <div *ngIf="productDetail.qtyAvailable === 0 && productDetail.type !== 'digital'">
                  <span class="out-of-stock">{{ 'out_of_stock' | translate }}</span>
                </div>
                <kwot-selected-variation
                  [product]="productDetail">
                </kwot-selected-variation>
              </div>
            </div>
          </div>

          <!-- public rfq detail -->
          <div class="product-section product-data mb-3" *ngIf="appConfig.type === 'user' && negotiationDetails?.negotiationOfferType === 'public' && publicRfqDetails">
            <div>
              <h4 class="text-capitalize">{{publicRfqDetails?.productName}}</h4>
              <p class="mb-1">
                <span>{{'category' | translate}}:</span> {{publicRfqDetails?.categoryName}}
              </p>
              <p class="mb-1">
                <span>{{'description' | translate}}:</span> {{publicRfqDetails?.description}}
              </p>
              <p class="mb-1">
                <span>{{'shipping_terms' | translate}}:</span> {{publicRfqDetails?.shippingTerms}}
              </p>
              <p class="mb-1">
                <span>{{'payment_terms' | translate}}:</span> {{publicRfqDetails?.paymentTerms}}
              </p>
            </div>
          </div>

          <!-- Upload signed contract document -->
          <div *ngIf="selectedProposal?.isGenerateOffer && appConfig.type === 'user'" class="mb-4">
            <div *ngIf="!selectedProposal?.signDocument">
              <h5>{{'upload_document' | translate}}</h5>
              <p>{{'upload_document_note' | translate}}</p>
              <input type="file" accept=".jpg, .gif, .png, .zip, .doc, .docx, .pdf" (change)="onFileSelected($event)">
              <button (click)="uploadFile()" class="btn btn-action w-50 mt-2">
                {{'upload' | translate}}
              </button>
            </div>
            <div *ngIf="selectedProposal?.signDocument" class="d-flex">
              <h5 class="me-3">{{'your_uploaded_document' | translate}}</h5>
              <a class="download-icon" [href]="selectedProposal?.signDocument">
                <img src="/assets/images/icons/download-grey.svg" class="img-fluid"/>
              </a>
            </div>
          </div>

          <div class="negotiation-box" *ngIf="negotiationDetails">
            <ng-container [ngTemplateOutlet]="showInitial"></ng-container>
            <ng-container *ngIf="negotiationDetails.proposals">
              <ng-container *ngFor="let item of negotiationDetails.proposals[selectedVendor]">
                <ng-container [ngTemplateOutlet]="proposalItem"
                              [ngTemplateOutletContext]="{item: item}"></ng-container>
              </ng-container>
            </ng-container>
          </div>

          <ng-template #proposalItem let-item="item">
            <div class="negotiation-details">
              <div class="icon-wrapper">
                <img class="gray-note" src="/assets/images/icons/gray-note.svg" alt=""
                     *ngIf="item?.status === 'in negotiation' || item?.status === 're-opened' || item?.status === 'initiated' || item?.status === 'shortlisted' || item?.status === 'withdraw'"
                     [ngClass]="{'d-block' : item?.status === 're-opened'}">
                <img class="orange-note" src="/assets/images/icons/orange-note.svg" alt=""
                     [ngClass]="{'d-none': item?.status === 'approved by buyer' || item?.status === 'approved by vendor' || item?.status === 'approved by admin' || item?.status === 're-opened' }">
                <img src="/assets/images/icons/check-green-bg.svg" alt=""
                     *ngIf="item?.status === 'approved by buyer' || item?.status === 'approved by vendor' || item?.status === 'approved by admin'">
              </div>
              <div>
                <div class="title justify-items-center negotiation-title-details mb-2">
                  <h5 class="fs-16 fw-bold me-2 mb-0">
                    {{getName(item, item.status, 'vendor')}}
                  </h5>
                  <span class="fs-16">{{item?.createdAt | date: 'MMMM, dd yyyy'}}</span>
                </div>
                <ng-container *ngIf="appConfig.type === 'vendor'">
                  <div class="mb-2 d-flex justify-content-between align-items-center">
                    <div class="status-details" *ngIf="item?.isShortListed || item?.isWithdraw">
                      <p *ngIf="item?.isShortListed && !item?.isWithdraw" class="pending-final-approve fs-16 m-0">
                        {{'shortlisted' | translate}}
                      </p>
                      <p *ngIf="item?.isWithdraw" class="pending-final-approve fs-16 m-0">
                        {{'withdrawn' | translate}}
                      </p>
                    </div>
                    <div class="withdraw-detail">
                      <button class="btn btn-action" (click)="withdrawProposal(item)"
                              *ngIf="!item?.isWithdraw">
                        {{'withdraw' | translate}}
                      </button>
                    </div>
                  </div>
                </ng-container>
                <div class="align-items-center unit-details mb-3">
                  <div class="d-flex align-items-center me-3">
                    <span class="neutral_60 fw-bold fs-16">{{'units' | translate}}</span>
                    <span class="sep px-2"> . </span>
                    <span class="text-black fs-16">{{item?.quantity}}</span>
                  </div>
                  <div class="d-flex align-items-center">
                    <span class="neutral_60 fw-bold fs-16">{{'rfq_price' | translate}}</span>
                    <span class="sep px-2"> . </span>
                    <span class="text-black fs-16">
<!--                        <kwot-currency [price]="item.price" tag="h6"-->
<!--                                       class="mb-0 fs-16"-->
<!--                                       [hasField]="false"-->
<!--                                       [type]="'order'"-->
<!--                                       [item]="item"-->
<!--                                       [platformFee]="false">-->
<!--                        </kwot-currency>-->
                       <kwot-currency [price]="item.price" tag="h6"
                                      class="mb-0 fs-16"
                                      [hasField]="false"
                                      [type]="appConfig.type === 'vendor' ? 'order_original' : 'order'"
                                      [item]="item"
                                      [platformFee]="false">
                        </kwot-currency>
                      </span>
                  </div>
                  <div class="d-flex align-items-center">
                    <span class="neutral_60 fw-bold fs-16">{{'shipping_cost' | translate}}</span>
                    <span class="sep px-2"> . </span>
                    <span class="text-black fs-16">
                        <kwot-currency [price]="item.shippingCost" tag="h6"
                                       class="mb-0 fs-16"
                                       [hasField]="false"
                                       [type]="appConfig.type === 'vendor' ? 'order_original' : 'order'"
                                       [item]="item"
                                       [platformFee]="false">
                        </kwot-currency>
                      </span>
                  </div>
                </div>
                <div class="message-description">
                  <span>{{item?.message}}</span>
                </div>
              </div>
            </div>

            <div class="pending-final-approve fs-16" *ngIf="item?.status === 'cancelled'">
              <span>{{'negotiation_cancelled' | translate}}</span>
            </div>
          </ng-template>
          <!-- Cancel Rfq -->
          <div class="cancel-rfq d-flex justify-items-center mt-3 cursor-pointer" (click)="openModel(cancelModal)" *ngIf="!selectedProposal?.isShortListed && selectedProposal?.status !== 'cancelled'">
            <img src="assets/images/icons/cancel-order.svg" class="img-fluid me-3" alt="cancel order"/>
            <span class="neutral_40 fw-bold">{{'cancel_rfq' | translate}}</span>
          </div>

          <ng-template #showInitial>
            <div class="negotiation-details">
              <div class="icon-wrapper">
                <img class="gray-note" src="/assets/images/icons/gray-note.svg" alt=""
                     *ngIf="negotiationDetails?.status !== 'approved by admin' || negotiationDetails?.status === 'in negotiation' || negotiationDetails?.status === 're-opened' || negotiationDetails?.status === 'initiated'"
                     [ngClass]="{'d-block' : negotiationDetails?.status === 're-opened'}">
                <img class="orange-note" src="/assets/images/icons/orange-note.svg" alt=""
                     [ngClass]="{'d-none': negotiationDetails?.status === 'approved by buyer' || negotiationDetails?.status === 'approved by vendor' || negotiationDetails?.status === 'approved by admin' || negotiationDetails?.status === 're-opened' }">
              </div>
              <div>
                <div class="title justify-items-center negotiation-title-details mb-2">
                  <h5 class="fs-16 fw-bold me-2 mb-0">
                    {{getName(negotiationDetails, negotiationDetails.status, 'user')}}
                  </h5>
                  <span class="fs-16">{{negotiationDetails?.createdAt | date: 'MMMM, dd yyyy'}}</span>
                </div>
                <div class="align-items-center unit-details mb-3">
                  <div class="d-flex align-items-center me-3">
                    <span class="neutral_60 fw-bold fs-16">{{'units' | translate}}</span>
                    <span class="sep px-2"> . </span>
                    <span class="text-black fs-16">{{negotiationDetails?.quantity}}</span>
                  </div>
                </div>
                <div class="message-description">
                  <span>{{negotiationDetails?.message}}</span>
                </div>
              </div>
            </div>
          </ng-template>
          <div *ngIf="appConfig.type === 'admin'">
            <div
              *ngIf="(selectedProposal?.isApproveByBuyer && selectedProposal?.isApproveByVendor) && selectedProposal?.status !== 'cancelled'">
              <div class="pending-final-approve fs-16">
                {{selectedProposal?.isApproveByAdmin ? ('approved' | translate) : ('pending_for_final_approval' | translate)}}
              </div>
              <div class="mt-3">
                <button class="btn btn-action py-3 mt-2" *ngIf="!selectedProposal?.isApproveByAdmin"
                        (click)="acceptRfq()">
                  {{'approve_proposal' | translate}}
                </button>
              </div>
            </div>
          </div>
          <ng-container>
            <ng-container *ngIf="!selectedProposal?.isApproveByAdmin">
              <div class="d-flex">
                <ng-container *ngIf="appConfig.type === 'vendor'">
                  <button class="btn btn-green-border mx-2 mt-1"
                          *ngIf="(!selectedProposal?.isApproveByBuyer && !selectedProposal?.isApproveByVendor) && selectedProposal?.status !== 'cancelled'"
                          (click)="openModel(updateRfqModal)">
                    {{'submit_proposal' | translate}}
                  </button>
                </ng-container>
                <ng-container
                  *ngIf="appConfig.type === 'user' && selectedProposal && !selectedProposal.isApproveByBuyer && selectedProposal?.status !== 'cancelled'">
                  <button class="btn btn-action mx-2 mt-1" (click)="acceptRfq()"
                          *ngIf="negotiationDetails && negotiationDetails.proposals &&
                        appConfig.type !== 'admin' && negotiationDetails.proposals[selectedVendor]
                         && negotiationDetails.proposals[selectedVendor].length > 0">
                    {{'accept_proposal' | translate}}
                  </button>
                </ng-container>

                <ng-container
                  *ngIf="appConfig.type === 'vendor' && selectedProposal && selectedProposal.isApproveByBuyer && !selectedProposal.isApproveByVendor && selectedProposal?.status !== 'cancelled'">
                  <button class="btn btn-action mx-2 mt-1" (click)="acceptRfq()"
                          *ngIf="negotiationDetails && negotiationDetails.proposals &&
                        appConfig.type !== 'admin' && negotiationDetails.proposals[selectedVendor]
                         && negotiationDetails.proposals[selectedVendor].length > 0">
                    {{'accept_proposal' | translate}}
                  </button>
                </ng-container>
              </div>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="selectedProposal?.status !== 'expired'">
            <div class="d-flex justify-items-center mt-3"
                 *ngIf="appConfig.type !== 'admin' && selectedProposal?.isApproveByAdmin ">
              <button class="btn btn-green-border mx-2" (click)="downloadLetter()">
                {{'download_offer_letter' | translate}}
              </button>
              <button class="btn btn-action mx-2" *ngIf="appConfig.type === 'user'" (click)="addToCart()">
                {{'go_to_shopping_cart' | translate}}
              </button>
            </div>
            <div class="pending-final-approve fs-16"
                 *ngIf="(appConfig.type === 'user' && !selectedProposal?.isApproveByAdmin && selectedProposal?.isApproveByBuyer) ||
                        (appConfig.type === 'vendor' && !selectedProposal?.isApproveByAdmin && selectedProposal?.isApproveByVendor)">
                {{ 'pending_final_approval_by_admin' | translate }}
            </div>
            <div class="pending-final-approve fs-16" *ngIf="negotiationDetails?.status === 'cancelled'">
              <span>{{'negotiation_cancelled' | translate}}</span>
            </div>
          </ng-container>
          <ng-container *ngIf="selectedProposal?.status === 'expired'">
            <div class="pending-final-approve fs-16">
              <span>{{'proposal_expired' | translate}}</span>
            </div>
          </ng-container>

          <ng-container *ngIf="appConfig.type === 'admin' && negotiationDetails?.negotiationOfferType === 'public' && selectedProposal && !negotiationDetails?.confirmTermSheet ">
            <button class="btn btn-action mt-3" (click)="confirmTermSheet()">
              {{'confirm_term_sheet' | translate}}
            </button>
          </ng-container>

          <div class="mt-4" *ngIf="appConfig.type !== 'vendor'" [ngClass]="{'d-none': appConfig.type === 'user' && negotiationDetails?.negotiationOfferType === 'public'}">
            <h4 class="fw-bold">{{'proposals_list' | translate}}</h4>
            <ng-container
              *ngIf="negotiationDetails && negotiationDetails.proposals && _object.keys(negotiationDetails.proposals).length <= 0">
              {{'receive_proposal_from_vendors' | translate}}.
            </ng-container>
            <ng-container
              *ngIf="negotiationDetails && negotiationDetails.proposals && _object.keys(negotiationDetails.proposals).length > 0">
              <div *ngFor="let key of _object.keys(negotiationDetails.proposals)">
                <ng-container [ngTemplateOutlet]="proposalList"
                              [ngTemplateOutletContext]="{proposal: negotiationDetails.proposals[key][0], key: key}"></ng-container>
              </div>
              <ng-template #proposalList let-proposal="proposal" let-key="key">
                <div class="user-details" (click)="selectedVendor !== key ? selectProposal(key) : null"
                     [ngClass]="{'active': selectedVendor === key }">
                  <div class="user-profile" *ngIf="!proposal?.vendorId?.logo && !proposal?.vendorId?.image">
                    <img class="img-fluid" src="assets/images/logo-small-round.svg" alt="">
                  </div>
                  <div class="user-profile" *ngIf="proposal?.vendorId?.logo || proposal?.vendorId?.image">
                    <img class="img-fluid"
                         [src]="proposal?.vendorId?.image ? proposal?.vendorId?.image : proposal?.vendorId?.logo"
                         alt="">
                  </div>
                  <div class="details">
                    <div class="top-details">
                      <div class="name">
                        <h2 class="fs-14 fw-bold mb-0">
                          {{proposal?.vendorId?.name}}
                        </h2>
                      </div>
                      <div class="time">
                        <p class="fs-12 mb-0">
                          {{proposal?.createdAt | date: 'dd/MM/YYYY'}}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="mb-3 col-lg-6 mb-4 rfq-offer-section">
        <!-- RFQ offer tabs -->
        <div class="negotiation-offers-tabs mt-3" *ngIf="appConfig.type === 'user' && negotiationDetails?.negotiationOfferType === 'public'">
          <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
              <button class="nav-link active w-100 fw-bold" id="offer-tab" data-bs-toggle="tab" data-bs-target="#offer"
                      type="button" role="tab" aria-selected="true" (click)="changeTab('all')">
                {{'offers' | translate}}
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button class="nav-link w-100 fw-bold" id="shortlisted-tab" data-bs-toggle="tab" data-bs-target="#shortlisted"
                      type="button" role="tab" aria-selected="false" (click)="changeTab('shortlisted')">
                {{'shortlisted' | translate}}
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button class="nav-link w-100 fw-bold" id="negotiation-tab" data-bs-toggle="tab" data-bs-target="#negotiation"
                      type="button" role="tab" aria-selected="false" (click)="changeTab('in_negotiation')">
                {{'under_negotiation' | translate}}
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button class="nav-link w-100 fw-bold" id="confirmed-tab" data-bs-toggle="tab" data-bs-target="#confirmed"
                      type="button" role="tab" aria-selected="false" (click)="changeTab('confirmed')">
                {{'confirmed' | translate}}
              </button>
            </li>
          </ul>
          <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade show active" id="offer" role="tabpanel" aria-labelledby="offer-tab">
              <ng-template [ngTemplateOutlet]="showProposals" *ngIf="!selectedVendor"></ng-template>
            </div>
            <div class="tab-pane fade" id="shortlisted" role="tabpanel" aria-labelledby="shortlisted-details">
              <ng-template [ngTemplateOutlet]="showProposals" *ngIf="!selectedVendor"></ng-template>
            </div>
            <div class="tab-pane fade show" id="negotiation" role="tabpanel" aria-labelledby="negotiation-tab">
              <ng-template [ngTemplateOutlet]="showProposals" *ngIf="!selectedVendor"></ng-template>
            </div>
            <div class="tab-pane fade show" id="confirmed" role="tabpanel" aria-labelledby="confirmed-tab">
              <ng-template [ngTemplateOutlet]="showProposals" *ngIf="!selectedVendor"></ng-template>
            </div>
          </div>
        </div>

        <!-- Reusable template for all tabs-->
        <ng-template #showProposals>
          <ng-container
            *ngIf="negotiationDetails && negotiationDetails.proposals && _object.keys(negotiationDetails.proposals).length > 0; else noProposal">
            <!-- Proposal list without vendor id group  -->
            <div *ngFor="let proposal of proposalList" class="mb-3">
              <div class="user-details" [ngClass]="{'active': selectedProposal?._id?.toString() === proposal?._id.toString() }">
                <div class="user-profile" *ngIf="!proposal?.vendorId?.logo && !proposal?.vendorId?.image">
                  <img class="img-fluid" src="assets/images/logo-small-round.svg" alt="">
                </div>
                <div class="user-profile" *ngIf="proposal?.vendorId?.logo || proposal?.vendorId?.image">
                  <img class="img-fluid"
                       [src]="proposal?.vendorId?.image ? proposal?.vendorId?.image : proposal?.vendorId?.logo"
                       alt="">
                </div>
                <div class="details">
                  <div class="top-details" >
                    <div class="name fs-14" (click)="tabType === 'all' ? null : selectProposal(proposal?.vendorId?._id)">
                      <h2 class="fs-14 fw-bold mb-0">
                        {{proposal?.vendorId?.name}}
                      </h2>
                      <span>
                        {{'qty' | translate}}: {{proposal.quantity}}
                      </span>
                      <span>
                        {{'rfq_price' | translate}}: {{proposal.price}}
                      </span>
                      <div>
                        {{proposal.message}}
                      </div>
                    </div>
                    <div class="short-list-icons pe-2" [ngClass]="{'not-allowed': proposal.status === 'cancelled' }">
                      <span (click)="updateShortList(proposal)">
                        <img *ngIf="proposal?.isShortListed" src="assets/images/icons/orange-fav.svg" class="wishlist_icon_img"/>
                        <img *ngIf="!proposal?.isShortListed" src="assets/images/icons/fav-black-border.svg" alt=""/>
                      </span>
                    </div>
                    <!--                    <div class="time">-->
                    <!--                      <p class="fs-12 mb-0">-->
                    <!--                        {{proposal?.createdAt | date: 'dd/MM/YYYY'}}-->
                    <!--                      </p>-->
                    <!--                    </div>-->
                  </div>
                  <div *ngIf=" tabType === 'in_negotiation'">
                    <button class="btn btn-action p-2 h-25 mt-1 w-50" *ngIf="!negotiationDetails?.confirmTermSheet" (click)="downloadTermSheet(proposal)">
                      {{'generate_term_sheet' | translate}}
                    </button>
                    <button class="btn btn-action p-2 h-25 mt-1 w-50" *ngIf="negotiationDetails?.confirmTermSheet" (click)="generateOrder(proposal)">
                      {{'generate_order' | translate}}
                    </button>
                  </div>
                  <div *ngIf="tabType === 'shortlisted'">
                    <button class="btn btn-action p-2 h-25 mt-1 w-50" [ngClass]="{'btn-disabled': proposal?.isGenerateOffer}" [disabled]="proposal?.isGenerateOffer" (click)="generateOffer(proposal)">
                      {{'generate_offer' | translate}}
                    </button>
                  </div>
                </div>
              </div>
            </div>


            <!-- Proposal list with vendor id group-->
<!--            <div *ngFor="let key of _object.keys(negotiationDetails.proposals)" class="mb-3">-->
<!--              <ng-container [ngTemplateOutlet]="proposalList"-->
<!--                            [ngTemplateOutletContext]="{proposal: negotiationDetails.proposals[key][0], key: key}"></ng-container>-->
<!--            </div>-->
<!--            <ng-template #proposalList let-proposal="proposal" let-key="key">-->
<!--              <div class="user-details"-->
<!--                   [ngClass]="{'active': selectedVendor === key }">-->
<!--                <div class="user-profile" *ngIf="!proposal?.vendorId?.logo && !proposal?.vendorId?.image">-->
<!--                  <img class="img-fluid" src="assets/images/logo-small-round.svg" alt="">-->
<!--                </div>-->
<!--                <div class="user-profile" *ngIf="proposal?.vendorId?.logo || proposal?.vendorId?.image">-->
<!--                  <img class="img-fluid"-->
<!--                       [src]="proposal?.vendorId?.image ? proposal?.vendorId?.image : proposal?.vendorId?.logo"-->
<!--                       alt="">-->
<!--                </div>-->
<!--                <div class="details">-->
<!--                  <div class="top-details" (click)="selectedVendor !== key ? selectProposal(key) : null">-->
<!--                    <div class="name fs-14">-->
<!--                      <h2 class="fs-14 fw-bold mb-0">-->
<!--                        {{proposal?.vendorId?.name}}-->
<!--                      </h2>-->
<!--                      <span>-->
<!--                        {{'qty' | translate}}: {{proposal.quantity}}-->
<!--                      </span>-->
<!--                      <span>-->
<!--                        {{'rfq_price' | translate}}: {{proposal.price}}-->
<!--                      </span>-->
<!--                      <div>-->
<!--                        {{proposal.message}}-->
<!--                      </div>-->
<!--                    </div>-->
<!--                    <div class="short-list-icons pe-2" [ngClass]="{'not-allowed': proposal.status === 'cancelled' }">-->
<!--                      <span (click)="updateShortList(proposal)">-->
<!--                        <img *ngIf="proposal?.isShortListed" src="assets/images/icons/orange-fav.svg" class="wishlist_icon_img"/>-->
<!--                        <img *ngIf="!proposal?.isShortListed" src="assets/images/icons/fav-black-border.svg" alt=""/>-->
<!--                      </span>-->
<!--                    </div>-->
<!--&lt;!&ndash;                    <div class="time">&ndash;&gt;-->
<!--&lt;!&ndash;                      <p class="fs-12 mb-0">&ndash;&gt;-->
<!--&lt;!&ndash;                        {{proposal?.createdAt | date: 'dd/MM/YYYY'}}&ndash;&gt;-->
<!--&lt;!&ndash;                      </p>&ndash;&gt;-->
<!--&lt;!&ndash;                    </div>&ndash;&gt;-->
<!--                  </div>-->
<!--                  <div *ngIf=" tabType === 'in_negotiation'">-->
<!--                    <button class="btn btn-action p-2 h-25 mt-1 w-50" *ngIf="!negotiationDetails?.confirmTermSheet" (click)="downloadTermSheet(proposal)">-->
<!--                      {{'generate_term_sheet' | translate}}-->
<!--                    </button>-->
<!--                    <button class="btn btn-action p-2 h-25 mt-1 w-50" *ngIf="negotiationDetails?.confirmTermSheet" (click)="generateOrder(proposal)">-->
<!--                      {{'generate_order' | translate}}-->
<!--                    </button>-->
<!--                  </div>-->
<!--                  <div *ngIf=" tabType === 'shortlisted'">-->
<!--                    <button class="btn btn-action p-2 h-25 mt-1 w-50" [ngClass]="{'btn-disabled': negotiationDetails?.isGenerateOffer}" [disabled]="negotiationDetails?.isGenerateOffer" (click)="generateOffer(proposal)">-->
<!--                      {{'generate_offer' | translate}}-->
<!--                    </button>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </ng-template>-->
          </ng-container>
          <ng-template #noProposal>
            <span> {{'you_have_no_proposal' | translate}}</span>
          </ng-template>
        </ng-template>

        <!-- Chat content for messages   -->
        <div class="mb-3 mt-3">
          <div class="title">
            <div class="mb-4">
              <div class="message-box" [ngClass]="{'d-none': !selectedVendor}">
                <div class="message-header">
                  <div class="user-details">
                    <div class="chat-back back-btn me-3" (click)="changeTab(tabType)" *ngIf="appConfig.type === 'user' && negotiationDetails?.negotiationOfferType === 'public'">
                      <img src="assets/images/icons/back.svg" class="img-fluid" alt="close icon"/>
                    </div>
                    <div class="user-profile" *ngIf="selectedRoom?.userId?._id === currentUser?._id; else personIdImage">
                      <img class="img-fluid" src="assets/images/logo-small-round.svg" alt="" *ngIf=" selectedRoom && (!selectedRoom?.personId?.logo && !selectedRoom?.personId?.image)">
                      <img class="img-fluid" *ngIf="(selectedRoom?.personId?.logo || !selectedRoom?.personId?.image)"
                           [src]="selectedRoom?.personId?.image ? selectedRoom?.personId?.image : selectedRoom?.personId?.logo"
                           alt="">
                    </div>
                    <ng-template #personIdImage>
                      <div class="user-profile">
                        <img class="img-fluid" src="assets/images/logo-small-round.svg" alt="" *ngIf="selectedRoom && (!selectedRoom?.userId?.logo && !selectedRoom?.userId?.image)">
                        <img class="img-fluid" *ngIf="(!selectedRoom?.userId?.logo && !selectedRoom?.userId?.image)"
                             [src]="selectedRoom?.userId?.image ? selectedRoom?.userId?.image : selectedRoom?.userId?.logo"
                             alt="">
                      </div>
                    </ng-template>
                    <div class="user-name ms-2"
                         *ngIf="selectedRoom && selectedRoom?.userId?._id === currentUser?._id; else personIdData">
                      <h6 class="m-0 fw-bold" *ngIf="!selectedRoom?.personId?.name; else senderName;">
                        {{selectedRoom?.personId?.firstName + selectedRoom?.personId?.lastName}}
                      </h6>
                      <ng-template #senderName>{{selectedRoom?.personId?.name}}</ng-template>
                    </div>
                    <ng-template #personIdData>
                      <div class="user-name ms-2">
                        <h6 class="m-0 fw-bold" *ngIf="!selectedRoom?.userId?.name; else senderName;">
                          {{selectedRoom?.userId?.firstName + selectedRoom?.userId?.lastName}}
                        </h6>
                        <ng-template #senderName>{{selectedRoom?.userId?.name}}</ng-template>
                      </div>
                    </ng-template>
                  </div>
                </div>
                <div class="message-list">
                  <div class="message-scroll" infiniteScroll [infiniteScrollDistance]="2"
                       [infiniteScrollContainer]="'.message-details'"
                       [infiniteScrollUpDistance]="1.5" (scrolledUp)="onScrollUp()">
                    <div class="message-details" #sectionSubscribe>
                      <div class="details" *ngIf="chatMessages[selectedRoom?._id]">
                        <div *ngFor="let dates of _object.keys(chatMessages[selectedRoom?._id])">
                          <div class="sticky-date">
                            <h6 class="fs-12 fw-bold m-0">
                              {{dates}}
                            </h6>
                          </div>

                          <ng-container *ngFor="let chat of chatMessages[selectedRoom?._id][dates]; let i = index;">
                            <div class="sent-message user-message" *ngIf="chat.id !== currentUser._id">
                              <div class="img-wrapper" *ngIf="!chat.data?.logo && !chat.data?.image">
                                <img class="img-fluid" src="assets/images/logo-small-round.svg" alt=" ">
                              </div>
                              <div class="img-wrapper" *ngIf="chat.data?.image || chat.data?.logo">
                                <img class="img-fluid" [src]="chat.data.image ? chat.data.image : chat.data.logo"
                                     alt=" ">
                              </div>
                              <div class="user-message-details">
                                <div class="name">
                                  <h6 class="mb-0">
                                    {{chat.data.name ? chat.data.name : chat.data.firstName + ' ' + chat.data.lastName}}
                                  </h6>
                                  <p class="fs-12 m-0">{{chat.data.createdAt | dateFormat: 'h:mm a'}}</p>
                                </div>
                                <div class="user-sent-message">
                                  <h6 class="fs-16 m-0">
                                    {{chat.message}}
                                  </h6>
                                </div>
                              </div>
                            </div>
                            <div class="replay-message user-message"
                                 *ngIf="chat?.id.toString() === currentUser?._id.toString()">
                              <div class="user-message-details">
                                <div class="name">
                                  <h6 class="mb-0">
                                    {{chat.data.name ? chat.data.name : chat.data.firstName + ' ' + chat.data.lastName}}
                                  </h6>
                                  <p class="fs-12 m-0">{{chat.data.createdAt | dateFormat: 'h:mm a'}}</p>
                                </div>
                                <div class="user-sent-message">
                                  <h6 class="fs-16 m-0">
                                    {{chat.message}}
                                  </h6>
                                </div>
                              </div>
                              <div class="img-wrapper" *ngIf="!chat.data?.logo && !chat.data?.image">
                                <img class="img-fluid" src="assets/images/logo-small-round.svg" alt="back-btn">
                              </div>
                              <div class="img-wrapper" *ngIf="chat.data?.image || chat.data?.logo">
                                <img class="img-fluid" [src]="chat.data?.image ? chat.data?.image : chat.data?.logo"
                                     alt="back-btn">
                              </div>
                            </div>
                          </ng-container>
                        </div>
                      </div>
                    </div>
                  </div>

                  <ng-container *ngIf="appConfig.type !== 'admin'">
                    <form class="chat-input form-group" [ngClass]="{'d-none': !selectedVendor || !selectedProposal}">
                      <div class="message-input form-group">
                        <div class="position-relative">
                          <textarea class="form-control" placeholder="{{'type_here_your_message' | translate}}" #chatmessage
                                    [(ngModel)]="chatMessage" name="chatMessage"
                                    (keyup)="sendMessage($event, chatmessage)"></textarea>
                          <div class="send-message">
                            <img class="send ms-2" src="/assets/images/icons/send-dark.png" alt="" (click)="submit()">
                          </div>
                        </div>
                      </div>
                    </form>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #updateRfqModal>
  <div class="delete-model">
    <div class="modal-header justify-content-center">
      <button type="button" class="btn-close m-0" (click)="modalRef?.hide()"></button>
    </div>
    <div class="modal-body pt-0">
      <div class="account-content">
        <div class="mb-3">
          <h2
            class="fw-bold text-center pt-3 m-0">{{'update_rfq_price' | translate}}</h2>
          <p class="fs-16 text-center m-0">{{'update_rfq_note' | translate}}</p>
        </div>
        <div class="product-price-details">
          <form [formGroup]="rfqForm" novalidate>
            <div class="form-group" *ngIf="negotiationDetails.negotiationOfferType !== 'product'">
              <div class="d-flex justify-content-between">
                <label class="mb-0 ms-2 me-auto fs-12 fw-bold ">{{'select_product' | translate}}</label>
                <ng-container *ngIf="(form.productId.dirty || form.productId.touched) && form.productId.errors">
                  <label *ngIf="form.price.errors.required" class="text-danger mb-0 me-2  fs-10 fw-bold">
                    {{ 'errors.is_required' | translate: {field: 'product' | translate} }}</label>
                </ng-container>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <kwot-custom-select
                    [formGroup]="rfqForm"
                    selectedValue="productId"
                    [searchable]="true"
                    [selectOptions]="products"
                    placeholder="{{'select_product' | translate}}">
                  </kwot-custom-select>
                </div>
                <div class="col-md-6">
                  <button *ngIf="negotiationDetails?.negotiationOfferType === 'public' && products.length <= 0" class="btn btn-action" [routerLink]="['/vendor/products/add-product']" (click)="modalRef.hide()">
                    {{'add_new_product' | translate}}
                  </button>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="d-flex justify-content-between">
                <label class="mb-0 ms-2 me-auto fs-12 fw-bold ">{{'quoted_price' | translate}}</label>
                <ng-container *ngIf="(form.price.dirty || form.price.touched) && form.price.errors">
                  <label *ngIf="form.price.errors.required" class="text-danger mb-0 me-2  fs-10 fw-bold">
                    {{ 'errors.is_required' | translate: {field: 'price' | translate} }}</label>
                </ng-container>
              </div>
              <div class="position-relative price-currency w-100 me-2">
                <input type="number" class="form-control" placeholder="Quoted Price" formControlName="price">
                <span>{{currencySymbol}}</span>
              </div>
            </div>
            <div class="form-group">
              <div class="d-flex justify-content-between">
                <label class="mb-0 ms-2 me-auto fs-12 fw-bold">{{'quantity' | translate}}</label>
                <ng-container *ngIf="(form.qty.dirty || form.qty.touched) && form.qty.errors">
                  <label *ngIf="form.qty.errors.required" class="text-danger mb-0 me-2  fs-10 fw-bold">
                    {{ 'errors.is_required' | translate: {field: 'quantity' | translate} }}</label>
                </ng-container>
              </div>
              <input type="number" class="form-control" [readOnly]="appConfig.type === 'vendor'"
                     [placeholder]="'placeholder.enter_here' | translate:{field: 'quantity' | translate}" formControlName="qty">
            </div>
            <div class="form-group">
              <div class="d-flex justify-content-between">
                <label class="mb-0 ms-2 me-auto fs-12 fw-bold">{{'shipping_cost' | translate}}</label>
                <ng-container
                  *ngIf="(form.shippingCost.dirty || form.shippingCost.touched) && form.shippingCost.errors">
                  <label *ngIf="form.shippingCost.errors.required" class="text-danger mb-0 me-2  fs-10 fw-bold">
                    {{ 'errors.is_required' | translate: {field: 'shipping_cost' | translate} }}</label>
                </ng-container>
              </div>
              <input type="number" class="form-control" [readOnly]="appConfig.type === 'user'"
                     [placeholder]="currencySymbol" formControlName="shippingCost">
            </div>
            <div class="form-group">
              <div class="d-flex justify-content-between">
                <label class="mb-0 ms-2 me-auto fs-12 fw-bold">{{'message' | translate}}</label>
                <ng-container *ngIf="(form.message.dirty || form.message.touched) && form.message.errors">
                  <label *ngIf="form.message.errors.required" class="text-danger mb-0 me-2  fs-10 fw-bold">
                    {{ 'errors.is_required' | translate: {field: 'message' | translate} }}</label>
                </ng-container>
              </div>
              <textarea class="form-control form-text-area" cols="30" rows="5"
                        placeholder="{{'placeholder.type_here' | translate:{field: 'message' | translate | lowercase} }}" formControlName="message"></textarea>
            </div>
            <ng-container>
              <button class="btn py-3 mt-2" [ngClass]="{'btn-action': rfqForm.valid, 'btn-disabled': rfqForm.invalid}"
                      [disabled]="rfqForm.invalid" (click)="reSendOffer()">
                {{ 'send_offer' | translate }}
              </button>
              <button type="button" class="btn py-3 mt-2 btn-action"
                      (click)="cancelOffer()">
                {{ 'cancel' | translate }}
              </button>
            </ng-container>
          </form>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #cancelModal>
  <div class="delete-model">
    <div class="modal-header justify-content-center">
      <h4
        class="modal-title fs-16 fw-bold text-center mb-0">{{'cancel_rfq' | translate}}</h4>
      <button type="button" class="btn-close m-0" (click)="modalRef?.hide()"></button>
    </div>
    <div class="modal-body pt-0">
      <div class="account-content">
        <div class="mb-4">
          <div class="img-wrapper text-center">
            <img class="img-fluid" src="/assets/images/confirm-background.png"/>
          </div>
          <h2
            class="fs-24 fw-bold text-center pt-4 m-0">{{'cancel_rfq_note' | translate}}</h2>
          <p class="fs-16 text-center m-0">{{'delete_account_sub_note' | translate}}</p>
        </div>
      </div>
    </div>
    <div class="modal-footer d-flex flex-wrap justify-content-evenly m-0 mb-4">
      <button type="button" class="remove-btn fs-14 fw-bold btn-green-border mx-2 btn" *ngIf="selectedProposal"
              aria-label="Close" (click)="cancelRfq()">{{'cancel_rfq_title' | translate}}</button>
      <button type="button" class="cancel-btn fs-14 fw-bold mx-2 btn-action btn" (click)="modalRef?.hide()"
              aria-label="Close">{{'keep_rfq' | translate}}</button>
    </div>
  </div>
</ng-template>
